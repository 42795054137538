const documentFormModel = {
    formId: 'documentForm',
    formField: {
      FileType: {
        name: 'FileType',
        label: 'File type',
        requiredErrorMesssag: 'File type is required'
      },
      File: {
        name: 'File',
        label: 'File',
        requiredErrorMesssag: 'File is required',
        fileSizeErrorMesssag: 'File size is too big',
        fileExtensionErrorMesssag: 'Incorrect file extension',
      }
    }
  };
  
  export default documentFormModel;