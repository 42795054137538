import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { FileDetailsParam } from "../models/document/fileDetailsParam";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { FileDto } from "../models/document/fileDto";

interface DocumentDetailsState {
    files: FileDto[] | null;
    fileDetails: FormData | null;
    status: ApiRequestStatus;
    downloadStatus: ApiRequestStatus;
    uploadStatus: ApiRequestStatus;
    deleteStatus: ApiRequestStatus;
}

const initialState: DocumentDetailsState = {
    files: null,
    fileDetails: null,
    status: ApiRequestStatus.Idle,
    downloadStatus: ApiRequestStatus.Idle,
    uploadStatus: ApiRequestStatus.Idle,
    deleteStatus: ApiRequestStatus.Idle,
}

export const getFilesAsync = createAsyncThunk<FileDto[], string>(
    'document/getFilesAsync',
    async (id, thunkAPI) => {
        try {
            return agent.documents.getFiles(id);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const downloadFileAsync = createAsyncThunk<FormData, FileDetailsParam>(
    'document/downloadFileAsync',
    async (downloadFileParam, thunkAPI) => {
        try {
            return agent.documents.getFile(downloadFileParam.id, downloadFileParam.fileName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveFileDetailsAsync = createAsyncThunk<boolean, FormData>(
    'document/saveFileDetailsAsync',
    async (formData, thunkAPI) => {
        try {
            return agent.documents.saveFile(formData);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const deleteFileAsync = createAsyncThunk<boolean, FileDetailsParam>(
    'document/deleteFileAsync',
    async (fileDetailsParam, thunkAPI) => {
        try {
            return agent.documents.deleteFile(fileDetailsParam.id, fileDetailsParam.fileName);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getFilesAsync.pending, (state) => {
            state.status = ApiRequestStatus.Pending;
        });

        builder.addCase(getFilesAsync.fulfilled, (state, action) => {
            state.files = action.payload;
            state.status = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getFilesAsync.rejected, (state) => {
            state.status = ApiRequestStatus.Rejected;
        });

        builder.addCase(downloadFileAsync.pending, (state) => {
            state.downloadStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(downloadFileAsync.fulfilled, (state) => {
            state.downloadStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(downloadFileAsync.rejected, (state) => {
            state.downloadStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveFileDetailsAsync.pending, (state) => {
            state.uploadStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(saveFileDetailsAsync.fulfilled, (state, action) => {
            state.uploadStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(saveFileDetailsAsync.rejected, (state) => {
            state.uploadStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(deleteFileAsync.pending, (state) => {
            state.deleteStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(deleteFileAsync.fulfilled, (state, action) => {
            state.deleteStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(deleteFileAsync.rejected, (state) => {
            state.deleteStatus = ApiRequestStatus.Rejected;
        });
    })
})
