import { FormHelperText, Grid, TextareaAutosize, Typography } from '@mui/material';
import { UseControllerProps, useController } from 'react-hook-form';
import AppInformationText from './AppInformationText';

var normalStyles = {
  width: '100%',
  borderColor: '#c7c1c1',
  borderRadius: '4px'
};

var errorStyles = {
  ...normalStyles,
  border: '2px solid red'
};

interface Props extends UseControllerProps {
  label: string;
  placeholder?: string;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  information_text_with_label?: string;
  information_text_with_textarea?: string;
  variant?: "outlined"
}

export default function AppTextareaInput(props: Props) {
  const { fieldState, field } = useController({ ...props, defaultValue: '' });

  return (
    <>
      <Grid container rowSpacing={1}>

        <Grid item xs={11} md={11} sm={11}>
          <Typography variant='body2' sx={{ color: '#727070' }}>{props.label}</Typography>
          {props.information_text_with_label &&
            <div style={{ marginTop: '-37px', marginLeft: '70px' }}>
              <AppInformationText information_text={props.information_text_with_label} placement={'right'} />
            </div>}
        </Grid>

        <Grid item xs={1} md={1} sm={1}></Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={11}>
          <TextareaAutosize
            minRows={10}
            style={fieldState.error ? errorStyles : normalStyles}
            className="rcsiTextField"
            {...props}
            {...field}
          />
          <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error?.message}</FormHelperText>
        </Grid>

        <Grid item xs={1}>
          {props.information_text_with_textarea && <AppInformationText information_text={props.information_text_with_textarea} />}
        </Grid>
      </Grid>
    </>
  );
}
