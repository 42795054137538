import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/layout/App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'react-redux';
import { store } from './app/store/configureStore';
import { AuthenticationResult, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize();

if (!msalInstance.getActiveAccount() && msalInstance.getActiveAccount() !== null &&
  msalInstance.getAllAccounts().length > 0) {
  msalInstance.setActiveAccount(msalInstance.getActiveAccount());
}

msalInstance.addEventCallback((event: EventMessage) => {
  const accountInformation = event.payload as AuthenticationResult;
  if (event.eventType === EventType.LOGIN_SUCCESS && accountInformation.account) {
    msalInstance.setActiveAccount(accountInformation.account);
  }
})

msalInstance.enableAccountStorageEvents();

export const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <Provider store={store}>
        <App msalInstance={msalInstance} />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
