import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"

interface Props {
    showDialog: boolean;
    title: string;
    subTitle: string;
    skipButtonClick: () => void;
    yesButtonClick: () => void;
    noButtonClick: () => void;
}

export default function UnsavedChangesDialog(props: Props) {
    const { showDialog, title, subTitle, skipButtonClick, yesButtonClick, noButtonClick } = props;

    return (
        <Dialog
            open={showDialog}
            onClose={skipButtonClick}
            aria-labelledby="unsaved-changes-dialog"
            aria-describedby="unsaved-changes-dialog"
        >
            <DialogTitle id="unsaved-changes-dialog">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="unsaved-changes-dialog">
                    {subTitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button variant="contained" color="primary"
                    onClick={yesButtonClick} autoFocus>
                    Yes
                </Button>
                <Button onClick={noButtonClick}>No</Button>
            </DialogActions>
        </Dialog>
    )
}