import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { FAMDetailsDto } from "../models/famDetails/famDetailsDto";

interface famDataState {
    famDetails: FAMDetailsDto | null;
    famDetailsStatus: ApiRequestStatus;
}

const initialState: famDataState = {
    famDetails: null,
    famDetailsStatus: ApiRequestStatus.Idle
}

export const getFamDetailsAsync = createAsyncThunk<FAMDetailsDto, string>(
    'fam/getFamDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.famDetails.getFamDetailsAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const famSlice = createSlice({
    name: 'famSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getFamDetailsAsync.pending, (state) => {
            state.famDetailsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(getFamDetailsAsync.fulfilled, (state, action) => {
            state.famDetails = action.payload;
            state.famDetailsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getFamDetailsAsync.rejected, (state) => {
            state.famDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
