import { Box, Paper, Grid, Typography, Button } from "@mui/material";

interface Props {
    header: string;
    body: string;
}

export default function SuccessResponseMessage({ header, body }: Props) {
    return <>
        <Box sx={{ flexGrow: 1, minWidth: "650px", mt: '10% !important' }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%' }}>
                    <Typography variant='h4'>{header}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>{body}</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%' }}>
                    <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                        Start again
                    </Button>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}
