import "yup-phone-lite";
import * as yup from 'yup';
import DirectDebitFormModel from "./directDebitFormModel";
import { MESSAGE_NOT_A_VALUE } from "../../utils/constant";
import * as ibantools from 'ibantools';
import { mapValues } from "lodash";

const {
  formField: {
    ProductType,
    FirstName,
    Surname,
    BIC,
    IBAN,
    TypeOfDirectDebit,
    PaymentFrequency,
    Amount
  }
} = DirectDebitFormModel;


export const DirectDebitValidationSchemas = yup.lazy(obj =>
  yup.object(
    mapValues(obj, (value, key) => {
      if (key === ProductType.name) {
        return yup.string().required(`${ProductType.requiredErrorMsg}`);
      }

      if (key === FirstName.name) {
        return yup.string().required(`${FirstName.requiredErrorMsg}`);
      }

      if (key === Surname.name) {
        return yup.string().required(`${Surname.requiredErrorMsg}`);
      }

      if (key.includes(`${BIC.name}`)) {
        return yup.string().test('validateBic',
          `${BIC.incorrectBicErrorMsg}`,
          function (item) {

            if (item === "") return true;

            return item !== undefined && ibantools.isValidBIC(item);
          }
        );
      }

      if (key.includes(`${IBAN.name}`)) {
        return yup.string().required(`${IBAN.requiredErrorMsg}`).test(
          'validateIbanNumber',
          `${IBAN.incorrectIbanErrorMsg}`,
          function (item) {
            return ibantools.validateIBAN(item).valid;
          }
        );
      }

      if (key.includes(`${TypeOfDirectDebit.name}`)) {
        return yup.string().required(`${TypeOfDirectDebit.requiredErrorMsg}`);
      }

      if (key.includes(`${PaymentFrequency.name}`)) {
        return paymentFrequencyValidationSchema;
      }

      if (key.includes(`${Amount.name}`)) {
        return yup.number().min(0.1, Amount.minErrorMsg)
          .required(`${Amount.requiredErrorMsg}`)
          .typeError(`${Amount.typeErrorMsg}`);
      }

      return yup.mixed();
    })
  )
);

const paymentFrequencyValidationSchema = yup.string().required(`${PaymentFrequency.requiredErrorMsg}`)
  .when([TypeOfDirectDebit.name],
    ([typeOfDirectDebit], schema) => {
      return schema.test(`${PaymentFrequency.name}`,
        `${PaymentFrequency.requiredErrorMsg}`,
        function (paymentFrequency) {
          if (paymentFrequency === MESSAGE_NOT_A_VALUE) {
            return true;
          }
          if (typeOfDirectDebit !== "" && paymentFrequency === "") {
            return false;
          }
          return true;
        });
    });
