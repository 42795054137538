import React from 'react';
import { useMsal, useAccount } from '@azure/msal-react';
import axios from 'axios';
import { loginRequest } from '../../authConfig';
import { AuthenticationResult } from '@azure/msal-browser';

interface RequestInterceptorProps {
    children: JSX.Element,
}

const RequestInterceptor: React.FC<RequestInterceptorProps> = ({ children }: RequestInterceptorProps) => {
    const { instance, accounts } = useMsal();
    const account = useAccount(accounts[0]);

    axios.interceptors.request.use(async (config) => {
        if (!account) {
            throw Error('No active account! Verify a user has been signed in.');
        }

        const response: AuthenticationResult = await instance.acquireTokenSilent({
            ...loginRequest,
            account,
        });
        
        const bearer = `Bearer ${response.accessToken}`;
        config.headers.Authorization = bearer;

        return config;
    });

    return (
        <>
            {children}
        </>
    );
};

export default RequestInterceptor;