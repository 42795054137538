export const msalConfig = {
    auth: {
        clientId: String(process.env.REACT_APP_CLIENT_ID),
        authority: String(process.env.REACT_APP_AUTHORITY),
        redirectUri: String(process.env.REACT_APP_REDIRECT_URI),
        postLogoutRedirectUri: String(process.env.REACT_APP_POST_LOGOUT_URI),
        navigateToLoginRequestUrl: true
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    },
    system: {
        allowRedirectInIframe: true,
        loggerOptions: {
            loggerCallback: (level: any, message: any, containsPii: any) => {
                if (containsPii) {
                    return;
                }
            }
        }
    },
    allowNativeBroker: false
};

export const loginRequest = {
    scopes: ["openid", "profile", String(process.env.REACT_APP_SCOPES)],
};

export const silentRequest = {
    scopes: ["openid", "profile", String(process.env.REACT_APP_SCOPES)],
    loginHint: "example@domain.net",
};