const ManualPaymentFormModel = {
  formField: {
    MembershipYear: {
      name: 'MembershipYear',
      label: 'Membership year*',
      requiredErrorMsg: 'Membership year is required'
    },
    Fund: {
      name: 'Fund',
      label: 'Fund*',
      requiredErrorMsg: 'Fund is required'
    },
    Amount: {
      name: "Amount",
      label: "Amount*",
      invalidErrorMsg: 'Invalid Amount.',
      requiredErrorMsg: 'Amount is required.',
      minErrorMsg: 'Amount must be a positive number.'
    },
    Currency: {
      name: "Currency",
      label: "Currency*",
      requiredErrorMsg: 'Currency is required.'
    },
    PaymentType: {
      name: 'PaymentType',
      label: 'Payment type*',
      requiredErrorMsg: 'Payment type is required.'
    },
    PaymentDate: {
      name: 'PaymentDate',
      label: 'Payment date*',
      requiredErrorMsg: 'Payment date is required'
    },
    PaymentMethod: {
      name: "PaymentMethod",
      label: "Payment method*",
      requiredErrorMsg: 'Payment method is required'
    },
    PaymentNotes: {
      name: "PaymentNotes",
      label: "Payment notes"
    },
    Products: {
      name: 'Products',
      requiredErrorMsg: 'At least one products is required'
    },
    Reference: {
      name: "Reference",
      label: "Reference"
    }
  }
};

export default ManualPaymentFormModel;
