import { Box, Grid, Paper, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { MapPaymentMethodsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import { PaymentMethodDto } from "../../app/models/manualPayment/paymentMethodDto";
import AppSelectList from "../../app/components/AppSelectList";
import AppDatePicker from "../../app/components/AppDatePicker";
import AppTextInput from "../../app/components/AppTextInput";
import ManualPaymentFormModel from "./common/manualPaymentFormModel";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import { DropdownItem } from "../../app/models/common/dropdownItem";

interface Props {
    currencies: DropdownItem[];
    paymentMethods: PaymentMethodDto[];
}

export default function PaymentDetails({ currencies, paymentMethods }: Props) {
    const {
        formField: {
            Currency,
            PaymentDate,
            PaymentMethod,
            Reference,
            PaymentNotes
        }
    } = ManualPaymentFormModel;

    const methods = useFormContext();
    const { control } = methods;

    return <>
        <Box sx={{ flexGrow: 1, minWidth: "500px" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'left' }}>Set currency</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={Currency.name} label={Currency.label} items={currencies} />
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box >

        <Box sx={{ flexGrow: 1, minWidth: "500px" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ marginTop: '-1%' }}>
                    <Typography variant='h6' sx={{ fontWeight: "500", textAlign: 'left' }}>Payment info</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={PaymentDate.name}
                        label={PaymentDate.label}
                        maxDate={new Date()} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={PaymentMethod.name} label={PaymentMethod.label}
                        items={MapPaymentMethodsToDropdownItems(paymentMethods)} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Reference.name} label={Reference.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={PaymentNotes.name} label={PaymentNotes.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}