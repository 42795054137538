import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import AppSelectList from "../../app/components/AppSelectList";
import { MapPaymentFrequencyToDropdownItems, MapProductsToDropdownItems, MapTypeOfDirectDebitsToDropdownItems } from "../../app/slices/convertStaticDataToDropdownItems";
import { FormProvider, useForm } from "react-hook-form";
import AppTextInput from "../../app/components/AppTextInput";
import { yupResolver } from "@hookform/resolvers/yup";
import { DirectDebitValidationSchemas } from "../../app/models/directDebit/directDebitValidationSchemas";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import DirectDebitFormModel from "../../app/models/directDebit/directDebitFormModel";
import { DirectDebitTypeEnum } from "../../app/enums/directDebitTypeEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../app/utils/constant";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { referenceDataAsync } from "../../app/slices/referenceDataSlice";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import { getFamDetailsAsync } from "../../app/slices/famSlice";
import { ProductDto } from "../../app/models/manualPayment/productDto";
import { getPersonalDetailsAsync } from "../../app/slices/personSlice";
import { EntityTypeEnum } from "../../app/enums/entityTypeEnum";

export default function ManualPaymentDirectDebit() {

    const history = useHistory();

    const queryParams = new URLSearchParams(window.location.search);
    const customerId = queryParams.get("customerId");
    const entityType = queryParams.get("entityType");

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(DirectDebitValidationSchemas)
    });

    const { control, trigger, watch, getValues, setValue, reset, formState: { isDirty } } = methods;
    const [typeOfDirectDebit, setTypeOfDirectDebit] = useState('');
    const [pageLoading, setPageLoading] = useState(false);
    const [filteredProductTypes, setFilteredProductTypes] = useState<ProductDto[]>([]);

    const dispatch = useAppDispatch();
    const { productTypes } = useAppSelector(state => state.referenceData);
    const { famDetails } = useAppSelector(state => state.famDetails);
    const { personalDetails } = useAppSelector(state => state.person);

    const {
        formField: {
            ProductType,
            FirstName,
            Surname,
            FullName,
            BIC,
            IBAN,
            TypeOfDirectDebit,
            PaymentFrequency,
            Amount
        }
    } = DirectDebitFormModel;

    const payDirectDebit = async () => {

        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {

            }
            else if (isDirty === false) {

            }
        }
    }

    useEffect(() => {
        if ((productTypes === null || productTypes?.length === 0) &&
            (famDetails === null && customerId !== null && personalDetails === null)) {
            setPageLoading(true);

            const allPromise = async () => await Promise.all([
                dispatch(referenceDataAsync()),
                dispatch(getFamDetailsAsync(customerId)),
                dispatch(getPersonalDetailsAsync(customerId))
            ]);

            allPromise().finally(() => {
                setPageLoading(false);
            });
        }
    }, [dispatch, productTypes, famDetails, personalDetails, customerId]);

    useEffect(() => {
        if (productTypes && productTypes !== null && productTypes.length > 0) {
            let productTypeList: ProductDto[] = [];

            if (entityType?.toLowerCase() === EntityTypeEnum.ACCOUNT.toLowerCase()) {
                let donationProduct = productTypes.find(x => x.code?.toUpperCase() === PaymentTypes.DONATION);
                donationProduct && productTypeList.push(donationProduct);
            }

            if (famDetails !== null && famDetails.famDetailsId !== null) {
                let anualMembershipProduct = productTypes.find(x => x.code?.toUpperCase() === PaymentTypes.FAM_ANUAL_MEMBERSHIP);
                anualMembershipProduct && productTypeList.push(anualMembershipProduct);
            }

            setFilteredProductTypes(productTypeList);
        }
    }, [productTypes, famDetails, entityType]);

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === TypeOfDirectDebit.name && type === 'change') {
                setTypeOfDirectDebit(getValues(TypeOfDirectDebit.name));
            }
        });
    }, [TypeOfDirectDebit.name, watch]);

    useEffect(() => {
        if (filteredProductTypes.length > 0) {
            if (famDetails !== null && famDetails.famDetailsId === null) {
                setValue(ProductType.name, filteredProductTypes?.find(x => x.code === PaymentTypes.DONATION)?.code);
            }
            else {
                setValue(ProductType.name, filteredProductTypes?.find(x => x.code === PaymentTypes.FAM_ANUAL_MEMBERSHIP)?.code);
            }
        }
    }, [filteredProductTypes, famDetails]);

    useEffect(() => {
        reset({
            [FirstName.name]: personalDetails?.firstName,
            [Surname.name]: personalDetails?.lastName
        })
    }, [personalDetails]);

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }} component={Paper}>

            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <Typography variant='h4'>Direct debit</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList control={control} name={ProductType.name} label={ProductType.label}
                        items={MapProductsToDropdownItems(filteredProductTypes?.filter(x => x.visible === true) ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={FirstName.name} label={FirstName.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Surname.name} label={Surname.label} />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>
                        {FullName.information_text}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={BIC.name} label={BIC.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={IBAN.name} label={IBAN.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppSelectList
                        control={control}
                        name={TypeOfDirectDebit.name}
                        label={TypeOfDirectDebit.label}
                        items={MapTypeOfDirectDebitsToDropdownItems()}
                    />
                </Grid>

                {typeOfDirectDebit === DirectDebitTypeEnum.RECURRENT &&
                    <Grid item xs={12}>
                        <AppSelectList
                            control={control}
                            name={PaymentFrequency.name}
                            label={PaymentFrequency.label}
                            items={MapPaymentFrequencyToDropdownItems()}
                        />
                    </Grid>
                }

                <Grid item xs={12}>
                    <AppTextInput control={control} name={Amount.name} label={Amount.label} type="number" />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={() =>
                        history.push(`${String(new URLSearchParams(window.location.search).get("cancelUrl"))}`)
                    }>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={0.5}></Grid>

                <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={payDirectDebit}>
                        Submit
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </FormProvider>
}
