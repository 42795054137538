import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { DocumentType } from "../models/document/documentType";
import { ProductDto } from "../models/manualPayment/productDto";
import { PaymentMethodDto } from "../models/manualPayment/paymentMethodDto";
import { ReferenceDataDto } from "../models/manualPayment/referenceDataDto";
import { FundDto } from "../models/manualPayment/fundDto";
import { Currency } from "../models/document/currency";

interface referenceDataState {
    documentTypes: DocumentType[] | null;
    productTypes: ProductDto[] | null;
    paymentMethods: PaymentMethodDto[] | null;
    funds: FundDto[] | null;
    currencies: Currency[] | null;

    referenceDataStatus: ApiRequestStatus;
    fundsReferenceDataStatus: ApiRequestStatus;
}

const initialState: referenceDataState = {
    documentTypes: null,
    productTypes: null,
    paymentMethods: null,
    funds: null,
    currencies: null,

    referenceDataStatus: ApiRequestStatus.Idle,
    fundsReferenceDataStatus: ApiRequestStatus.Idle
}

export const documentTypesAsync = createAsyncThunk<DocumentType[]>(
    'referenceData/documentTypesAsync',
    async (_, thunkAPI) => {
        try {
            return agent.referenceData.documentTypes();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const referenceDataAsync = createAsyncThunk<ReferenceDataDto>(
    'referenceData/referenceDataAsync',
    async (_, thunkAPI) => {
        try {
            return agent.referenceData.referenceDataAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const fundsReferenceDataAsync = createAsyncThunk<FundDto[]>(
    'referenceData/fundsReferenceDataAsync',
    async (_, thunkAPI) => {
        try {
            return agent.referenceData.fundsReferenceDataAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const referenceDataSlice = createSlice({
    name: 'referenceData',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(documentTypesAsync.pending, (state) => {
            state.referenceDataStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(documentTypesAsync.fulfilled, (state, action) => {
            state.documentTypes = action.payload;
            state.referenceDataStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(documentTypesAsync.rejected, (state) => {
            state.fundsReferenceDataStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(fundsReferenceDataAsync.pending, (state) => {
            state.fundsReferenceDataStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(fundsReferenceDataAsync.fulfilled, (state, action) => {
            state.funds = action.payload;
            state.fundsReferenceDataStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(fundsReferenceDataAsync.rejected, (state) => {
            state.referenceDataStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(referenceDataAsync.pending, (state) => {
            state.referenceDataStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(referenceDataAsync.fulfilled, (state, action) => {
            state.productTypes = action.payload.productTypes;
            state.paymentMethods = action.payload.paymentMethods;
            state.currencies = action.payload.currencies;

            state.referenceDataStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(referenceDataAsync.rejected, (state) => {
            state.referenceDataStatus = ApiRequestStatus.Rejected;
        });
    })
})
