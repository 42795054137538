import * as yup from 'yup';
import documentFormModel from './documentFormModel';

const {
  formField: {
    FileType,
    File
  }
} = documentFormModel;

export const documentValidationSchema = yup.object().shape({
  [FileType.name]: yup.string().required(`${FileType.requiredErrorMesssag}`),
  [File.name]: yup.mixed()
    .required(`${File.requiredErrorMesssag}`)
    .test('is-correct-file', `${File.fileExtensionErrorMesssag}`, checkIfFilesAreTooBig)
    .test('is-big-file', `${File.fileSizeErrorMesssag}`, checkIfFilesAreCorrectType)
})

function checkIfFilesAreTooBig(file?: any): boolean {
  let valid = true
  if (file) {
    const size = file.size / 1024 / 1024
    if (size > 5) {
      valid = false
    }
  }
  return valid
}

function checkIfFilesAreCorrectType(file?: any): boolean {
  let valid = true
  if (file) {
    if (!['text/plain',
      'image/jpeg',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      valid = false
    }
  }
  return valid
}