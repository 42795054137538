import { Route, Switch } from 'react-router';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Container, ThemeProvider, createTheme } from '@mui/material';
import FileList from '../../features/Document.SPA/FileList';
import { AuthenticatedTemplate, MsalProvider, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import RequestInterceptor from '../api/RequestInterceptor';
import { useEffect } from 'react';
import { isProductionMode } from '../utils/util';
import ManualPaymentDirectDebit from '../../features/DirectDebit.SPA/ManualPaymentDirectDebit';
import ManualPaymentForm from '../../features/ManualPayment.SPA/ManualPaymentForm';

function App({ msalInstance }: any) {

  return (
    <>
      <MsalProvider instance={msalInstance}>
        <Pages />
      </MsalProvider>
    </>
  );
}

function Pages() {

  const paletteType = 'light';

  const theme = createTheme({
    palette: {
      mode: paletteType,
      primary: {
        light: "#F08080",
        main: "#DE1834",
        dark: "#DC143C",
        contrastText: "#fff"
      },
      background: {
        default: '#f3f3f3'
      }
    },
    components: {
      // GLOBAL CHANGES TO DEFAULT/BASE THEME
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true, // No more ripple, on the whole application!
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: "none"
            , '&:hover': {
              boxShadow: "none"
            },
            fontSize: '1rem',
          }
        }
      },
      MuiContainer: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          }
        }
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            marginTop: '0px !important',
            marginBottom: '0px !important',
          },
          outlined: {
            border: '0',
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: 'outlined',
        },
        styleOverrides: {
          root: {
            borderRadius: 0,
            boxShadow: "none"
            , '&:hover': {
              boxShadow: "none"
            },
            fontSize: '1rem',
          }
        }
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {

          },
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#222',
          }
        },
      },
      MuiStep: {
        styleOverrides: {
          root: {
            paddingLeft: '4px',
          }
        },
      }
    },
  })

  useEffect(() => {
    if (!isProductionMode()) {
      const metaRobots = document.createElement('meta');
      metaRobots.name = 'robots';
      metaRobots.content = 'noindex,nofollow';
      document.head.appendChild(metaRobots);
    }
  }, []);

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleRedirect = () => {
    instance.loginRedirect({
      ...loginRequest,
      prompt: 'create',
    });
  };

  if (isAuthenticated === false) {
    handleRedirect()
  }

  return (
    <div className="App">
      <AuthenticatedTemplate>
        <RequestInterceptor>
          <ThemeProvider theme={theme}>
            <ToastContainer position='top-center' hideProgressBar theme='colored' />
            <Container>
              <Switch>
                <Route path="/" exact component={FileList} />
                <Route path="/files" component={FileList} />
                <Route path="/payment" component={ManualPaymentForm} />
                <Route path="/direct-debit" component={ManualPaymentDirectDebit} />
              </Switch>
            </Container>
          </ThemeProvider>
        </RequestInterceptor>
      </AuthenticatedTemplate>
    </div>
  );
}

export default App;
