import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { CreateManualPaymentDTO } from "../models/manualPayment/createManualPaymentDTO";

interface manualPaymentDataState {
    paymentId: string | null;
    submitManualPaymentStatus: ApiRequestStatus;
}

const initialState: manualPaymentDataState = {
    paymentId: null,
    submitManualPaymentStatus: ApiRequestStatus.Idle
}

export const submitManualPaymentAsync = createAsyncThunk<string, CreateManualPaymentDTO>(
    'manualPayment/submitManualPaymentAsync',
    async (manualPaymentDTO, thunkAPI) => {
        try {
            return agent.manualPayment.submitManualPayment(manualPaymentDTO);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const manualPaymentSlice = createSlice({
    name: 'manualPaymentSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(submitManualPaymentAsync.pending, (state) => {
            state.submitManualPaymentStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(submitManualPaymentAsync.fulfilled, (state, action) => {
            state.paymentId = action.payload;
            state.submitManualPaymentStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(submitManualPaymentAsync.rejected, (state) => {
            state.submitManualPaymentStatus = ApiRequestStatus.Rejected;
        });
    })
})
