import { useState } from "react";
import { toast } from "react-toastify";
import { Button, Grid, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { documentValidationSchema } from "./documentValidationSchema";
import documentFormModel from "./documentFormModel";
import AppFileInput from "../../app/components/AppFileInput";
import AppSelectList from "../../app/components/AppSelectList";
import LoadingComponent from "../../app/components/LoadingComponent";
import UnsavedChangesDialog from "../../app/components/UnsavedDialog";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import { FileDto } from "../../app/models/document/fileDto";
import { MapDocumentTypesToDropdownList } from "../../app/slices/convertStaticDataToDropdownItems";
import { saveFileDetailsAsync, getFilesAsync } from "../../app/slices/documentSlice";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { DocumentType} from "../../app/models/document/documentType";

interface Props {
    containerId: string;
    personId: string;
    entityType: string;
    documentTypes: DocumentType[];
    files: FileDto[] | null;
}

export default function UploadFile({ containerId, personId, entityType, documentTypes, files }: Props) {
    const { File, FileType } = documentFormModel.formField;

    const [isDuplicateFileUploaded, setIsDuplicateFileUploaded] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const dispatch = useAppDispatch();

    const { uploadStatus } = useAppSelector(state => state.document);
    
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(documentValidationSchema)
    });

    const form = methods.watch();
    const { control, handleSubmit, getValues } = methods;

    const _handleSubmit = async () => {
        if (files?.find(x => x.name?.trim() === (form.File as File).name.trim())) {
            setIsDuplicateFileUploaded(true);
            setShowDialog(true);
        }
        else {
            setIsDuplicateFileUploaded(false);
            saveFileDetails();
        }
    };

    const saveFileDetails = () => {
        const formData = new FormData();
        formData.append("Id", containerId);
        formData.append("PersonId", personId);
        formData.append("EntityType", entityType);
        formData.append("FileType", getValues([FileType.name]).toString());
        formData.append("File", form.File as File);

        dispatch(saveFileDetailsAsync(formData)).then((response) => {
            if ((response.payload as boolean) === false) {
                dispatch(getFilesAsync(containerId));
                toast.success('File uploaded successfully.');
            }
            else if ((response.payload as boolean) === true) {
                toast.error('File not uploaded.');
            }
        });
    }

    if (uploadStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message='Processing details...' />
    }

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(_handleSubmit)}>
                    <Grid container rowSpacing={4}>

                        <Grid item xs={12}></Grid>

                        <Grid item xs={12}>
                            <Typography component="h1" variant="h4" alignItems="left">
                                Upload new file
                            </Typography>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={11}>
                            <AppSelectList control={control} label={FileType.label} name={FileType.name}
                                items={MapDocumentTypesToDropdownList(documentTypes)} />
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={11} sx={{ alignSelf: 'center' }}>
                            <AppFileInput control={control} label={File.label} name={File.name} />
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={6} sx={{textAlign: 'start'}}>
                            <Button type="submit"
                                variant="contained" color="primary">
                                Upload File
                            </Button>
                        </Grid>

                        <Grid item xs={5}></Grid>

                        <Grid item xs={12}></Grid>

                    </Grid>

                    {isDuplicateFileUploaded === true &&
                        <UnsavedChangesDialog showDialog={showDialog}
                            title={'Duplicate file found.'}
                            subTitle={'The you uploaded is already in the system. Do you want to replace this file? or else please rename file and upload again.'}
                            yesButtonClick={function (): void {
                                saveFileDetails();
                                setShowDialog(false);
                            }} noButtonClick={function (): void {
                                setShowDialog(false);
                            }} skipButtonClick={function (): void {
                                setShowDialog(false);
                            }} />}
                </form>
            </FormProvider>
        </>
    );
}
