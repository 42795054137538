import { Box, Paper, Grid, Typography } from "@mui/material";

export default function ManualPaymentHeader() {

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>

            <Grid container rowSpacing={4}>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginTop: '2%' }}>
                    <Typography variant='h4'>Manual payment</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginLeft: '2%', marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        
                    </Typography>
                </Grid>

            </Grid>
        </Box>
    </>
}
