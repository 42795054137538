import { Button, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import AppSelectList from "../../app/components/AppSelectList";
import ManualPaymentFormModel from "./common/manualPaymentFormModel";
import AppTextInput from "../../app/components/AppTextInput";
import { PaymentTypes } from "../../app/enums/paymentTypes";
import { ProductModel } from "./common/productModel";
import { DropdownItem } from "../../app/models/common/dropdownItem";
import { useState } from "react";

interface Props {
    funds: DropdownItem[];
    years: DropdownItem[];
    addProductClicked: (productModel: ProductModel) => void;
}

export default function PaymentItems({ funds, years, addProductClicked }: Props) {
    const {
        formField: {
            PaymentType,
            MembershipYear,
            Amount,
            Currency,
            Fund
        }
    } = ManualPaymentFormModel;

    const methods = useFormContext();
    const { control, getValues } = methods;

    const [membershipYearOrFundError, setMembershipYearOrFundError] = useState<string>();
    const [amountError, setAmountError] = useState<string>();

    const addPaymentItem = () => {
        let membershipYear = getValues(MembershipYear.name);
        let amount = getValues(Amount.name);
        let currency = getValues(Currency.name);
        let fundId = getValues(Fund.name);
        let productType = getValues(PaymentType.name);

        let hasMembershipYearOrFundError = false;
        let hasAmountError = false;

        if (membershipYear === '' && getValues(PaymentType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP) {
            setMembershipYearOrFundError(MembershipYear.requiredErrorMsg);
            hasMembershipYearOrFundError = true;
        }
        else if (fundId === '' && getValues(PaymentType.name) === PaymentTypes.DONATION) {
            setMembershipYearOrFundError(Fund.requiredErrorMsg);
        }
        else {
            setMembershipYearOrFundError('');
        }

        if (amount === '') {
            setAmountError(Amount.requiredErrorMsg);
            hasAmountError = true;
        } else if (amount <= 0) {
            setAmountError(Amount.minErrorMsg);
            hasAmountError = true;
        }
        else {
            setAmountError('');
        }

        if (hasMembershipYearOrFundError === true || hasAmountError === true) return;

        if (((getValues(PaymentType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP && membershipYear !== '')
            || (getValues(PaymentType.name) === PaymentTypes.DONATION && fundId !== '')) && amount !== '') {
            addProductClicked({
                productType: productType,
                fundId: fundId,
                membershipYear: membershipYear,
                amount: Number(amount),
                currency: currency
            });
        }
    }

    return <>
        <Grid item xs={4}>
            <Grid item xs={12}>
                {getValues(PaymentType.name) === PaymentTypes.FAM_ANUAL_MEMBERSHIP &&
                    <AppSelectList control={control} name={MembershipYear.name} label={MembershipYear.label}
                        items={years.sort((a, b) => a.id > b.id ? 1 : -1)} />
                }

                {getValues(PaymentType.name) === PaymentTypes.DONATION &&
                    <AppSelectList control={control} name={Fund.name} label={Fund.label}
                        items={funds} />
                }
            </Grid>

            <Grid item xs={12}>
                <p className="Mui-error css-1wc848c-MuiFormHelperText-root">{membershipYearOrFundError}</p>
            </Grid>
        </Grid>

        <Grid item xs={4}>
            <Grid item xs={12}>
                <AppTextInput control={control} name={Amount.name} label={Amount.label} type="number" />
            </Grid>

            <Grid item xs={12}>
                <p className="Mui-error css-1wc848c-MuiFormHelperText-root">{amountError}</p>
            </Grid>
        </Grid>

        <Grid item xs={4} sx={{ marginTop: '7px' }}>
            <Button variant="contained" color="primary" onClick={addPaymentItem}>
                Add payment item
            </Button>
        </Grid>

        <Grid item xs={12}></Grid>
    </>
}
