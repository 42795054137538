import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material"

interface Props {
    showDialog: boolean;
    title: string;
    subTitle: string;
    showYesNoButtons: boolean;
    skipButtonClick?: () => void;
    yesButtonClick?: () => void;
    noButtonClick?: () => void;
}

export default function ConfirmationDialog(props: Props) {
    const { showDialog, title, subTitle, skipButtonClick, yesButtonClick, noButtonClick } = props;

    return (
        <Dialog
            open={showDialog}
            onClose={skipButtonClick}
            aria-labelledby="unsaved-changes-dialog"
            aria-describedby="unsaved-changes-dialog"
        >
            <DialogTitle id="unsaved-changes-dialog">
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="unsaved-changes-dialog">
                    {subTitle}
                </DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                {props.showYesNoButtons === true &&
                    <>
                        <Button variant="contained" color="primary"
                            onClick={yesButtonClick} autoFocus>
                            Yes
                        </Button>
                        <Button onClick={noButtonClick}>No</Button>
                    </>
                }

                {props.showYesNoButtons === false &&
                    <>
                        <Button variant="contained" color="primary"
                            onClick={skipButtonClick} autoFocus>
                            Ok
                        </Button>
                    </>
                }
            </DialogActions>
        </Dialog>
    )
}