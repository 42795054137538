import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PersonDto } from "../models/personalDetails/personDto";

interface personDataState {
    personalDetails: PersonDto | null;
    personalDetailsStatus: ApiRequestStatus;
}

const initialState: personDataState = {
    personalDetails: null,
    personalDetailsStatus: ApiRequestStatus.Idle
}

export const getPersonalDetailsAsync = createAsyncThunk<PersonDto, string>(
    'person/getPersonalDetailsAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.person.getPersonalDetailsAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const personSlice = createSlice({
    name: 'personSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getPersonalDetailsAsync.pending, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Pending;
        });

        builder.addCase(getPersonalDetailsAsync.fulfilled, (state, action) => {
            state.personalDetails = action.payload;
            state.personalDetailsStatus = ApiRequestStatus.Fulfilled;
        });

        builder.addCase(getPersonalDetailsAsync.rejected, (state) => {
            state.personalDetailsStatus = ApiRequestStatus.Rejected;
        });
    })
})
