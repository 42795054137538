import * as yup from 'yup';
import ManualPaymentFormModel from './manualPaymentFormModel';

const {
  formField: {
    Currency,
    PaymentDate,
    PaymentMethod,
    Products }
} = ManualPaymentFormModel;

const PaymentDetailsValidationSchema = yup.object().shape({
  [Currency.name]: yup.string().required(`${Currency.requiredErrorMsg}`),
  [PaymentDate.name]: yup.string().required(`${PaymentDate.requiredErrorMsg}`),
  [PaymentMethod.name]: yup.string().required(`${PaymentMethod.requiredErrorMsg}`),
});

const ProductDetailsValidationSchema = yup.object().shape({
  [Products.name]: yup.mixed()
    .test({
      message: Products.requiredErrorMsg,
      test: (products: any) => {
        return products && products.length > 0;
      }
    })
});

export const ManualPaymentValidationSchemas = [
  PaymentDetailsValidationSchema,
  ProductDetailsValidationSchema
];
